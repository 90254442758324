import React, { useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDispatch, useSelector } from 'react-redux';

import { NEWMEDIA_VISIBLE_ID } from '../../constants/product.constants';
import { removeFromShoppingCart, addShoppingCartMessage } from '../../redux/user/actions';
import { formatDecimalWithCurrency, formatDate } from '../../services/utils';
import names from '../../constants/shoppingCart';
import MediaImage from '../MediaImage';
import './checkoutGridItem.scss';
import Loader from '../Loader';

const CheckoutGridItem = ({
  fields,
  item,
  deactivateFlow,
  orderCardResponse,
  paymentApiResponse,
  hideRemove = false,
  lastStep,
  filterItemLength,
  index,
  step,
  disableBorderBottom = false,
  currentBalance,
}) => {
  const customerId = useSelector((state) => state.user.customerId);
  const visibleId = useSelector((state) => state.user.selectedCard.visibleId);
  const logo = useSelector((state) => state.products.logos[item.serviceProvider]?.Logo);
  const guestUserInfo = useSelector((reduxState) => reduxState.user.guestUserInfoEnabled);
  const guestSCT = useSelector((reduxState) => reduxState.user.guestShoppingCartToken);
  const anonymous = useSelector((state) => state.user && state.user.anonymous);
  const selectedCard = useSelector((state) => state.user.selectedCard);
  let customerData = orderCardResponse?.data?.Order?.Customer?.FareMedias
    ? orderCardResponse.data.Order.Customer.FareMedias
    : paymentApiResponse?.data?.Order?.Customer?.FareMedias;
  customerData = customerData && customerData.length > 0 && customerData[0];
  let currentfareMedias = useSelector((reduxState) => reduxState.user.fareMedias);
  currentfareMedias = currentfareMedias.find((data) => data.visibleId === visibleId);
  const transitPasses =
    currentfareMedias &&
    currentfareMedias?.products &&
    currentfareMedias?.products.length > 0 &&
    currentfareMedias?.products;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  /*
  const removeItem = (orderLineId) => {
    const data = {
      customerId,
      mediaId: visibleId,
      orderLineId,
      Sct: guestUserInfo?.isGuestLogin ? guestSCT.sct : '',
      Cct: guestUserInfo?.isGuestLogin ? guestSCT.cct : '',
      IsGuestUser: guestUserInfo?.isGuestLogin ? true : false,
      IsAnonymousUser: anonymous ? true : false,
    };
    dispatch(removeFromShoppingCart(data));
    dispatch(addShoppingCartMessage(''));
  };

  */


  const removeItem = (orderLineId) => {
    //  console.log("3: inside removeItem " + isLoading);
    return new Promise((resolve, reject) => {
      try {
        const data = {
          customerId,
          mediaId: visibleId,
          orderLineId,
          Sct: guestUserInfo?.isGuestLogin ? guestSCT?.sct : guestSCT?.sisct,
          Cct: guestUserInfo?.isGuestLogin ? guestSCT?.cct : '',
          IsGuestUser: guestUserInfo?.isGuestLogin ? true : false,
          IsAnonymousUser: anonymous ? true : false,
        };

        dispatch(removeFromShoppingCart(data));
        dispatch(addShoppingCartMessage(''));
        resolve(); // Resolve the promise on successful completion
        //console.log("4: Inside remove item before setIsLoading as false");
        //setIsLoading(false);
        //console.log("5: Inside remove item after setIsLoading as false");
        // console.log("4: complete removeItem " + isLoading);
      } catch (error) {
        reject(error); // Reject the promise if there's an error
      }
    });
  };

  /*

  const handleRemoveClick = async (orderLineId) => {
    console.log("1: setIsLoading as true", isLoading);
    setIsLoading(true);
    setTimeout(() => {
      console.log("Checking isLoading after timeout:", isLoading);
    }, 4000);
    try {
      console.log("2: before call removeItem", isLoading);
      await removeItem(orderLineId);
    } catch (error) {
      console.error("Error removing item:", error);
    } finally {
      console.log("5: before setIsLoading as false", isLoading);
      setIsLoading(false);
      console.log("6: After setIsLoading as false", isLoading);
    }
  };

  */


  const handleRemoveClick = async (orderLineId) => {
    // Set loading to true
    //console.log("1: setIsLoading as true " + isLoading);
    setIsLoading(true);
    try {
      //console.log("2: before call removeItem " + isLoading);
      await removeItem(orderLineId); // Directly call removeItem
    } catch (error) {
      //console.error("Error removing item:", error);
    } finally {
      //console.log("5: before setIsLoading as false " + isLoading);
      //setIsLoading(false);
      if (isLoading) {
        setTimeout(() => {
          setIsLoading(false);
        }, 4000);
        //console.log("6: After setIsLoading as false " + isLoading);
      }
      else {
        //console.log("7: Inside else before setIsLoading as false " + isLoading);
        setTimeout(() => {
          setIsLoading(false);
        }, 4000);
        //console.log("8: Inside else after setIsLoading as false " + isLoading);
      }
    }
  };


  /*
   const handleRemoveClick = (orderLineId) => {
     // Set loading to true
     setIsLoading(true);
   
     try {
       removeItem(orderLineId); // Directly call removeItem
     } catch (error) {
       console.error("Error removing item:", error);
     } finally {
       setTimeout(() => {
         setIsLoading(false);
       }, 4000);
     }
   };
 
   */


  return (
    <>
      {isLoading ? (
        (() => {
          //console.log("Rendering <Loader /> component " + isLoading);
          return (
            <div className="loader-container">
              <Loader />
              <div>Loading...</div>
            </div>
          );
        })()
      ) : (
        <div
          className={`checkoutGridItem ${disableBorderBottom ? `checkoutGridItem-disable-border` : ``}`}
        >
          <div className="checkoutGridItem-content">
            <div className="checkoutGridItem-logo-block">
              {item?.serviceProvider !== 'Presto' ? (
                <img alt={logo?.Alt} className="checkoutGrid-logo" src={logo?.Src} />
              ) : (
                <MediaImage prestoCard medium />
              )}
            </div>
            <div className="checkoutGridItem-data-block">
              <div
                className={`checkoutGridItem-block ${item.isManageAutoRenew || item?.autorenew ? `show-by-col` : ``
                  }`}
              >
                <div className="checkoutGridItem-title">{item?.name}</div>
                <div className="checkoutGridItem-date">
                  {item?.name === !names.funds || item.isManageAutoRenew || item?.autorenew
                    ? `${formatDate(item?.validityStartDate)} - ${formatDate(item?.validityEndDate)}`
                    : null}
                </div>
                {!hideRemove && (
                  <div
                    role="link"
                    className="checkoutGridItem-remove"
                    aria-label={`${item?.name} ${fields.removeButtonTextA11y?.value}`}
                    tabIndex="0"
                    //onClick={() => removeItem(item?.orderLineId)}
                    onClick={() => handleRemoveClick(item?.orderLineId)}
                    onKeyPress={() => null}
                  >
                    <Text field={fields.removeButtonText} />
                  </div>
                )}
              </div>

              {filterItemLength - 1 == index && (item?.nickNameValue || item?.cardLanguage) && (
                <div className="checkoutGridItem-details">
                  {item?.nickNameValue && (
                    <div className="checkoutGridItem-nickname">
                      <span>
                        <Text field={fields.NickName} />{' '}
                      </span>
                      <span>{item?.nickNameValue}</span>
                    </div>
                  )}
                  {item?.cardLanguage && (
                    <div className="checkoutGridItem-language">
                      <span>
                        <Text field={fields.Language} />{' '}
                      </span>
                      <span>{item?.cardLanguage}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
            {item?.isManageAutoRenew ? null : (
              <div className="checkoutGridItem-value-block quantity">
                <div className="checkoutGrid-value">{item?.quantity}</div>
              </div>
            )}
            {item?.isManageAutoRenew ? null : (
              <div className="checkoutGridItem-value-block price">
                <div className="checkoutGrid-value">{formatDecimalWithCurrency(item?.subTotal)}</div>
              </div>
            )}
          </div>
          {deactivateFlow && (
            <div className="checkoutGridItem-details-deactivate">
              <p>
                {fields?.NickNameLabel.value}: {selectedCard?.nickName}
              </p>
              <p>
                {fields?.BalanceLabel.value}: {formatDecimalWithCurrency(currentBalance)}
              </p>
              <p className="checkoutgrid-flex">
                <span>{fields?.TransitPassLabel.value}: </span>{' '}
                <div className="checkout-grid-passes">
                  {transitPasses &&
                    transitPasses.length > 0 &&
                    transitPasses.map((pass) => {
                      if (pass.ProductName !== null) {
                        return <p>{pass.ProductName}</p>;
                      }
                    })}
                </div>
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CheckoutGridItem;
